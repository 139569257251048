import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';

// @ts-ignore
// import Lightbox from 'react-awesome-lightbox';
// import 'react-awesome-lightbox/build/style.css';
import LightboxModified from '../lightbox/index';
import { EncodedResource, getSource, ResourcePreviewApi, ResourcePreviewState } from './EncodedResource';

const empty = { encodedResources: [], currentIndex: -1 } as ResourcePreviewState;

export const ImagePreview = forwardRef((props, ref: Ref<ResourcePreviewApi>) => {
  const [previewState, setPreviewState] = useState<ResourcePreviewState>(empty);

  useImperativeHandle<ResourcePreviewApi, ResourcePreviewApi>(ref, () => ({
    showResource(resource: EncodedResource) {
      if (resource) {
        setPreviewState({ encodedResources: [{ title: resource.title, url: getSource(resource) }], currentIndex: 0 });
      }
    },
    showResources(resources: EncodedResource[], currentIndex: number) {
      if (resources) {
        setPreviewState({
          encodedResources: resources.map(resource => ({ title: resource.title, url: getSource(resource) })),
          currentIndex: currentIndex,
        });
      }
    },
  }));

  if (previewState.encodedResources.length === 0) {
    return <></>;
  }

  if (previewState.encodedResources.length === 1) {
    return (
      <LightboxModified
        image={previewState.encodedResources[0].url}
        title={previewState.encodedResources[0].title}
        allowDownload={true}
        onClose={() => setPreviewState(empty)}
      />
    );
  }

  return (
    <LightboxModified
      images={previewState.encodedResources}
      startIndex={previewState.currentIndex}
      allowDownload={true}
      onClose={() => setPreviewState(empty)}
    />
  );
});
