/**
 * index.tsx configure sentry & handlers
 */

import React from 'react';
import * as Sentry from '@sentry/react';
import { Button, ConfigProvider, Result } from 'antd';
import { setLocale } from 'yup';
import { yupLocaleDe } from './yupLocaleDe';
import deDE from 'antd/es/locale/de_DE';
import 'moment/locale/de-at';
import { AppAuth } from './AppAuth';

setLocale(yupLocaleDe);

Sentry.init({
  dsn: (window as any)._env_.SENTRY_DSN,
  release: (window as any)._env_.SENTRY_RELEASE,
  enabled: (window as any)._env_.SENTRY_ENABLED.toLowerCase().trim() === 'true',
  environment: (window as any)._env_.SENTRY_ENVIRONMENT,
});

const fallbackComponent = () => {
  return (
    <Result
      status="error"
      title="Ein unbekanntes Problem ist aufgetreten"
      subTitle="Bitte laden Sie die Anwendung neu"
      extra={[
        <Button key="refresh" type="primary" onClick={() => (window.location.href = '/')}>
          Anwendung neu laden
        </Button>,
      ]}
    />
  );
};

const sentryOptions = {
  lang: 'de',
  title: 'Ein unbekanntes Problem ist aufgetreten',
  successMessage: 'Danke! Ihr Feedback wurde erfolgreich gesendet',
};

const SentryWrapper: React.FC = () => {
  return (
    <ConfigProvider locale={deDE} prefixCls="ant">
      <Sentry.ErrorBoundary fallback={fallbackComponent} showDialog dialogOptions={sentryOptions}>
        <AppAuth />
      </Sentry.ErrorBoundary>
    </ConfigProvider>
  );
};

export default SentryWrapper;
