import { LocaleObject } from 'yup';

/* eslint-disable no-template-curly-in-string */
export const yupLocaleDe: LocaleObject = {
  mixed: {
    default: 'Das Feld ist ungültig',
    required: 'Eingabe verpflichtend',
    oneOf: 'Das Feld muss einem der folgenden Werte entsprechen: ${values}',
    notOneOf: 'Das Feld darf keinem der folgenden Werte entsprechen: ${values}',
  },
  string: {
    length: 'Das Feld muss genau ${length} Zeichen lang sein',
    min: 'Das Feld muss mindestens ${min} Zeichen lang sein',
    max: 'Das Feld darf höchstens ${max} Zeichen lang sein',
    matches: 'Das Feld muss wie folgt aussehen: "${regex}"',
    email: 'Das Feld muss eine gültige E-Mail-Adresse enthalten',
    url: 'Das Feld muss eine gültige URL sein',
    trim: 'Das Feld darf keine Leerzeichen am Anfang oder Ende enthalten',
    lowercase: 'Das Feld darf nur Kleinschreibung enthalten',
    uppercase: 'Das Feld darf nur Großschreibung enthalten',
  },
  number: {
    min: 'Die Zahl muss größer oder gleich ${min} sein',
    max: 'Die Zahl muss kleiner oder gleich ${max} sein',
    lessThan: 'Die Zahl muss kleiner sein als ${less}',
    moreThan: 'Die Zahl muss größer sein als ${more}',
    positive: 'Die Zahl muss positiv sein',
    negative: 'Die Zahl muss negativ sein',
    integer: 'Die Zahl muss eine ganze Zahl sein',
  },
  date: {
    min: 'Das Datum muss später sein als ${min}',
    max: 'Das Datum muss früher sein als ${max}',
  },
  object: {
    noUnknown: '${path}-Feld darf keine Schlüssel verwenden, die nicht im "Objekt-Shape" definiert wurden',
  },
  array: {
    min: 'Das Feld muss mindesten ${min} Einträge haben',
    max: 'Das Feld darf höchstens ${max} Einträge haben',
  },
  boolean: {},
};
/* eslint-enable no-template-curly-in-string */
