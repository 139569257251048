/**
 * App.tsx configure oidc/keycloak
 * Syncs also jwt cookie for BMP services (see gateway)
 */

import * as React from 'react';

import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context';

import LoadingIndicator from './components/LoadingIndicator';
import { AppQueryWrapper } from './AppQueryWrapper';
import { Alert } from 'antd';

// siehe https://github.com/authts/react-oidc-context
const oidcConfig = {
  authority: `${(window as any)._env_.KEYCLOAK_AUTH_URL}/realms/${(window as any)._env_.KEYCLOAK_REALM}`,
  client_id: (window as any)._env_.KEYCLOAK_CLIENT,
  client_secret: (window as any)._env_.CLIENT_SECRET,
  redirect_uri: window.location.href,
};

const setJwtCookie = (token: string, maxAge: number, expires: string) => {
  const domain = (window as any)._env_.COOKIE_DOMAIN;
  const secure = (window as any)._env_.COOKIE_SECURE === 'true' ? 'secure' : '';
  const jwtCookie = `jwt=${token};path=/;max-age=${maxAge};domain=${domain};${secure};${expires}`;
  document.cookie = jwtCookie;
};

const setTokenCookie = (token: string) => {
  setJwtCookie(token, 3600, '');
};

const removeTokenCookie = (token: string) => {
  setJwtCookie(token, -9999999, 'expires=Thu, 01 Jan 1970 00:00:01 GMT;');
};

const AuthEventHandler: React.FC = () => {
  const auth = useAuth();

  // automatically sign-in
  React.useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  if (!auth.isAuthenticated) {
    removeTokenCookie(auth.user?.access_token || '');
  }

  if (auth.activeNavigator || auth.isLoading) {
    return <LoadingIndicator type="app" />;
  }

  if (!auth.isAuthenticated) {
    return (
      <Alert
        type="error"
        message={
          <>
            Fehler beim Anmelden! <a href="/">Erneut versuchen.</a>
          </>
        }
        banner
      />
    );
  }

  setTokenCookie(auth.user?.access_token || '');

  return <AppQueryWrapper />;
};

export const AppAuth: React.FC = () => {
  return (
    <AuthProvider {...oidcConfig}>
      <AuthEventHandler />
    </AuthProvider>
  );
};
