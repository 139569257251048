/**
 * index.tsx handles cookie consent
 */

import React from 'react';
import ReactDOM from 'react-dom';

import styles from './index.module.css';

import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import LangAndSentryWrapper from './LangAndSentryWrapper';
import { Alert } from 'antd';

import { AppHeader, LogoType } from 'bit-ui_component_lib-b2b';

console.debug('started!');

const CookieCheck = () => {
  return (
    <>
      {getCookieConsentValue() ? (
        <LangAndSentryWrapper />
      ) : (
        <>
          <AppHeader logoType={LogoType.WRW} appNameLong="BMP" appNameShort="BMP" />
          <Alert
            style={{ margin: '40px' }}
            type="warning"
            message="Zustimmung für die Verwendung von Cookies fehlt"
            description="Die Applikation kann erst nach Zustimmung der Verwendung von Cookies verwendet werden."
            showIcon={true}
          />
          <CookieConsent
            disableStyles={true}
            overlayStyle={{
              position: 'fixed',
              left: '0px',
              top: '0px',
              width: '100%',
              height: '100%',
              zIndex: '999',
              backgroundColor: 'rgba(0,0,0,0.3)',
            }}
            containerClasses={styles.cookie_container}
            contentClasses={styles.cookie_content}
            buttonClasses={styles.cookie_button}
            onAccept={() => window.location.reload()}
            buttonText="Ich bin einverstanden"
            overlay={true}
          >
            Um die Baumanagerplattform nutzen zu können, müssen Sie der Verwendung von Cookies zustimmen.&nbsp;
            <a href="/cookie-policy.html" target="wrw.cookie-policy">
              Cookie-Policy
            </a>
          </CookieConsent>
        </>
      )}
    </>
  );
};
ReactDOM.render(<CookieCheck />, document.getElementById('root'));
