import React from 'react';

import { AppLayout, LogoType } from 'bit-ui_component_lib-b2b';
import { useUserMenu } from './menu/useUserMenu';
import UserActions from './usermenu/UserActions';

export const AppContent = () => {
  const { menuComponent, contentComponent } = useUserMenu();

  return (
    <AppLayout
      logoType={LogoType.WRW}
      appNameLong="BMP"
      appNameShort="BMP"
      menu={menuComponent}
      userMenu={<UserActions />}
      content={contentComponent}
    />
  );
};
