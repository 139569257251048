import React, { useEffect, useState } from 'react';
import styles from './UserActions.module.css';
import { Avatar, Button, Divider, Modal, notification, Popover, Tooltip, Typography } from 'antd';
import {
  BellTwoTone,
  ContainerOutlined,
  FileProtectOutlined,
  LoginOutlined,
  LogoutOutlined,
  MailOutlined,
  SecurityScanOutlined,
} from '@ant-design/icons';
import { useAuth } from 'react-oidc-context';
import jwtDecode from 'jwt-decode';

declare let window: any;

const { Text } = Typography;
const IFRAME_ID = 'account-page';

const openNotification = (message: String) => {
  notification['warning']({
    icon: <BellTwoTone twoToneColor="#FF0102" />,
    message: 'System Nachricht',
    description: message,
  });
};

function getAccountUrl() {
  return (window as any)._env_.ACCOUNT_URL;
}

const UserActions = () => {
  const auth = useAuth();
  const [isBenutzerMenuVisible, setBenutzerMenuVisible] = useState(false);
  const [bmpMessage, setBmpMessage] = useState<string | null>();
  const name = auth.user?.profile?.name || '';
  const username = auth.user?.profile?.preferred_username || '';
  const firstName = auth.user?.profile?.given_name || '';
  const lastName = auth.user?.profile?.family_name || '';

  useEffect(() => {
    const token = auth.user?.access_token;
    if (token) {
      const jwt = jwtDecode<any>(token);
      setBmpMessage(jwt.bmp_message || null);
    } else {
      setBmpMessage(null);
    }
  }, [auth.user?.access_token]);

  const title = (
    <div className={styles.usertitle}>
      <Text type="secondary">Eingeloggt als</Text>
      <p />
      <Text strong>{name || '<kein Name>'}</Text>
      <br />
      <Text>{username}</Text>
    </div>
  );

  const showBenutzerkonto = () => {
    setBenutzerMenuVisible(false);
    window.open(getAccountUrl(), "_blank", "noreferrer");
  };

  const content = (
    <div>
      <Button
        className={[styles.button].join(' ')}
        type={'link'}
        icon={<SecurityScanOutlined />}
        onClick={showBenutzerkonto}
      >
        Benutzerkonto/Passwort ändern
      </Button>
      <Button
        className={styles.logoutbutton}
        danger
        type={'link'}
        icon={<LogoutOutlined />}
        onClick={() => {
          setBenutzerMenuVisible(false);
          auth.signoutRedirect({ post_logout_redirect_uri: window.location.href.split('?')[0] });
        }}
      >
        Abmelden
      </Button>
      <Divider type="horizontal" />
      <Button
        className={[styles.button].join(' ')}
        type={'link'}
        icon={<ContainerOutlined />}
        onClick={() => {
          setBenutzerMenuVisible(false);
          window.open('/cookie-policy.html', 'wrw.cookie-policy');
        }}
      >
        Cookie-Policy
      </Button>
      <Button
        className={[styles.button].join(' ')}
        type={'link'}
        icon={<FileProtectOutlined />}
        onClick={() => {
          setBenutzerMenuVisible(false);
          window.open('/data-protection.html', 'wrw.data-protection');
        }}
      >
        Datenschutzerklärung
      </Button>
    </div>
  );

  return auth.isAuthenticated ? (
    <>
      <div>
        <div style={{ lineHeight: 'initial', display: 'flex', alignItems: 'center' }}>
          {bmpMessage && (
            <Tooltip title="System Nachricht">
              <div
                style={{
                  color: '#343434',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  marginRight: '15px',
                }}
              >
                <BellTwoTone
                  twoToneColor="#FF0102"
                  style={{ fontSize: '20px' }}
                  onClick={() => openNotification(bmpMessage)}
                />
              </div>
            </Tooltip>
          )}

          <Tooltip title="Kontakt aufnehmen">
            <a href="mailto:it@wrwks.at?subject=BMP" target="_blank" rel="noreferrer">
              <MailOutlined
                style={{
                  color: 'var(--wrw-text-color)',
                  fontSize: '20px',
                  marginRight: '15px',
                }}
              />
            </a>
          </Tooltip>

          <Tooltip title="Benutzermenü">
            <Popover
              content={content}
              title={title}
              placement="bottomRight"
              trigger="click"
              visible={isBenutzerMenuVisible}
              onVisibleChange={visible => setBenutzerMenuVisible(visible)}
            >
              <div style={{ color: '#343434', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Avatar
                  style={{
                    border: '1px solid var(--wrw-text-color)',
                    color: 'var(--wrw-text-color)',
                    backgroundColor: 'var(--wrw-primary-background-color)',
                  }}
                >
                  {((firstName || '').substring(0, 1) + (lastName + '').substring(0, 1)).toUpperCase()}
                </Avatar>
              </div>
            </Popover>
          </Tooltip>
        </div>
      </div>
    </>
  ) : (
    <a href="/#" onClick={() => auth.signinRedirect()}>
      <LoginOutlined /> Anmelden
    </a>
  );
};

export default UserActions;
