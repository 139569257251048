/**
 * App.tsx configure apollo client, and base settings such as locale

 */

import * as React from 'react';

import WindowEventHandler from './components/WindowEventHandler';
import { AppContent } from './AppContent';
import AxiosProvider from './axios/AxiosProvider';
import { QueryClient, QueryClientProvider } from 'react-query';

export const AppQueryWrapper: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <AxiosProvider>
      <QueryClientProvider client={queryClient}>
        <WindowEventHandler />
        <AppContent />
      </QueryClientProvider>
    </AxiosProvider>
  );
};
