import React from 'react';
import { ProjektInfo } from '../domain/Menu';
import { Col, Row, Tooltip } from 'antd';

import style from './ProjektHeader.module.css';

import { ProjektKennzeichenAvatars } from './ProjektKennzeichenAvatars';
import { ProjektStatus } from './ProjektStatus';

interface ProjektHeaderProps {
  projekt: ProjektInfo;
}

export const ProjektHeader: React.FC<ProjektHeaderProps> = props => {
  return (
    <>
      <Row className={style.topRow}>
        <Col span={18} className={style.kategorie}>
          {props.projekt.kategorie}
          <ProjektStatus className={style.status} projekt={props.projekt} />
        </Col>
        <Col span={6} className={style.sapHeader}>
          SAP Meldungsnummer
        </Col>
      </Row>
      <Row className={style.bottomRow}>
        <Col span={18} className={style.projectTitel}>
          {props.projekt.name},{' '}
          <Tooltip title={'TPL: ' + props.projekt.technischerPlatz.number} placement="top">
            {props.projekt.bezirk}. Bezirk
          </Tooltip>
          <ProjektKennzeichenAvatars className={style.kennzeichen} kennzeichen={props.projekt.kennzeichen} />
        </Col>
        <Col span={6} className={style.sapNumber}>
          {props.projekt.sapNummer.number}
        </Col>
      </Row>
    </>
  );
};
