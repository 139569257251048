import React, { ReactNode, useMemo } from 'react';
import { AxiosContext } from './AxiosContext';
import Axios from 'axios';

interface IAxiosProvider {
  children: ReactNode;
}

export default function AxiosProvider({ children }: IAxiosProvider) {
  const axiosMemo = useMemo(() => {
    const axiosInstance = Axios.create({
      baseURL: (window as any)._env_.API_LINK,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return axiosInstance;
  }, []);

  return <AxiosContext.Provider value={axiosMemo}>{children}</AxiosContext.Provider>;
}
