import React, { MutableRefObject, useCallback, useRef } from 'react';
import { useWindowEvent } from '../hooks/useWindowEvent';
import { ImagePreview } from './ImagePreview';
import { PdfPreview } from './PdfPreview';
import { downloadResource, EncodedResource, ResourcePreviewApi } from './EncodedResource';

const WindowEventHandler = () => {
  const imagePreviewRef = useRef<ResourcePreviewApi>() as MutableRefObject<ResourcePreviewApi>;
  const pdfPreviewRef = useRef<ResourcePreviewApi>() as MutableRefObject<ResourcePreviewApi>;

  const callback = useCallback(event => {
    switch (event.data.type) {
      case 'history.pushState': {
        window.history.pushState({}, document.title, event.data.url);
        break;
      }
      case 'preview.image': {
        window.focus();
        imagePreviewRef.current?.showResource({
          resource: event.data.resource,
          title: event.data.name,
        } as EncodedResource);
        break;
      }
      case 'preview.images': {
        window.focus();
        imagePreviewRef.current?.showResources(
          event.data.resources.map(
            (entry: any) =>
              ({
                resource: entry.resource,
                title: entry.name,
              } as EncodedResource)
          ),
          event.data.currentIndex
        );
        break;
      }
      case 'preview.pdf': {
        window.focus();
        pdfPreviewRef.current?.showResource({
          resource: event.data.resource,
          title: event.data.name,
        } as EncodedResource);
        break;
      }
      case 'download.resource': {
        downloadResource({ resource: event.data.resource, title: event.data.name } as EncodedResource);
        break;
      }
    }
  }, []);

  useWindowEvent('message', callback);

  return (
    <>
      <ImagePreview ref={imagePreviewRef} />
      <PdfPreview ref={pdfPreviewRef} />
    </>
  );
};

export default WindowEventHandler;
