import React from 'react';
import { ProjektInfo } from '../domain/Menu';
import { Alert } from 'antd';
import style from './ProjektStatus.module.css';


interface ProjektStatusProps extends React.HTMLAttributes<HTMLDivElement> {
  projekt: ProjektInfo
}

const ProjektStatusStandby: React.FC<ProjektStatusProps> = props => {
  return <Alert className={style.status} type="warning" message={props.projekt.standbyReason ? `STANDBY ${props.projekt.standbyReason}` : 'STANDBY'}></Alert>;
};
const ProjektStatusDeleted: React.FC<ProjektStatusProps> = props => {
  return <Alert className={style.status} type="error" message="GELÖSCHT"></Alert>;
};
const ProjektStatusFinished: React.FC<ProjektStatusProps> = props => {
  return <Alert className={style.status} type="success" message="ABGESCHLOSSEN"></Alert>;
};
const ProjektStatusCanceled: React.FC<ProjektStatusProps> = props => {
  return <Alert className={style.status} type="error" message="ABGEBROCHEN"></Alert>;
};

const STYLE_MAP = new Map<string, React.FC<ProjektStatusProps>>();
STYLE_MAP.set('STANDBY', ProjektStatusStandby);
STYLE_MAP.set('DELETED', ProjektStatusDeleted);
STYLE_MAP.set('FINISHED', ProjektStatusFinished);
STYLE_MAP.set('CANCELED', ProjektStatusCanceled);

export const ProjektStatus: React.FC<ProjektStatusProps> = props => {
  let comp = STYLE_MAP.get(props.projekt.status);
  return comp ? <span className={props.className}>{comp(props)}</span> : <></>;
};
