import {
  CameraOutlined,
  ClusterOutlined,
  DeliveredProcedureOutlined,
  EnvironmentOutlined,
  FileOutlined,
  FolderOutlined,
  FolderViewOutlined,
  FormatPainterOutlined,
  HistoryOutlined,
  HomeOutlined,
  IdcardOutlined,
  InboxOutlined,
  LeftOutlined,
  LineOutlined,
  ProfileOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ShopOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import * as React from 'react';

const ICON_MAPPING: any = {
  'Meine Aufgaben': <ScheduleOutlined />,
  Projekte: <ClusterOutlined />,
  'Back To Projekte': <LeftOutlined />,
  Übersicht: <ShopOutlined />,
  Projektbeteiligte: <TeamOutlined />,
  Lageplan: <EnvironmentOutlined />,
  Basisdaten: <SettingOutlined />,
  Projektvorbereitung: <InboxOutlined />,
  Aufgaben: <ProfileOutlined />,
  Aufgabendetails: <FileOutlined />,
  Gewerkdokumente: <FormatPainterOutlined />,
  Dokumente: <FolderOutlined />,
  Fotos: <CameraOutlined />,
  Gewerkedaten: <UserSwitchOutlined />,
  'Projekte Mitarbeiter': <SolutionOutlined />,
  Mieterliste: <IdcardOutlined />,
  Historie: <HistoryOutlined />,
  'Allgemeine Dokumente': <FolderViewOutlined />,
  'Meine Firma': <HomeOutlined />,
  'Plan Archiv': <DeliveredProcedureOutlined />,
};

const IconOrDefault = (name: any): React.ReactNode => ICON_MAPPING[name] || <LineOutlined />;

export { IconOrDefault };
