import React from 'react';
import { Alert, Button, Menu, Spin, Tabs } from 'antd';
import { IconOrDefault } from './UserMenuIcon';
import { MenuData, ProjektInfo, SecondLevelMenu, TopMenuInfo } from '../domain/Menu';
import styles from './useUserMenu.module.css';
import { HomeOutlined } from '@ant-design/icons';
import { ProjektHeader } from './ProjektHeader';
import { useAxios } from '../axios/useAxios';
import { useQuery } from 'react-query';

const { TabPane } = Tabs;

const InternalError = (
  <Alert
    style={{ margin: '40px' }}
    type="error"
    message="Interner Fehler"
    description="Unerwarteter Fehler beim Laden der Seite aufgetreten"
    showIcon={true}
    action={
      <Button type="link" danger href="/" icon={<HomeOutlined />}>
        Zur Startseite
      </Button>
    }
  />
);

const UnknownPage = (
  <Alert
    style={{ margin: '40px' }}
    type="error"
    message="Seite unbekannt"
    description="Für die angegebene URL konnte keine passende Seite gefunden werden"
    showIcon={true}
    action={
      <Button type="link" danger href="/" icon={<HomeOutlined />}>
        Zur Startseite
      </Button>
    }
  />
);

const InvalidSapNumber = (
  <Alert
    style={{ margin: '40px' }}
    type="error"
    message="Meldungsnummer ungültig"
    description="Die angegebene Meldungsnummer ist ungültig"
    showIcon={true}
    action={
      <Button type="link" danger href="/" icon={<HomeOutlined />}>
        Zur Startseite
      </Button>
    }
  />
);

const UnknownSapNumber = (
  <Alert
    style={{ margin: '40px' }}
    type="error"
    message="Projekt unbekannt"
    description="Für die angegebene Meldungsnummer konnte kein Projekt gefunden werden"
    showIcon={true}
    action={
      <Button type="link" danger href="/" icon={<HomeOutlined />}>
        Zur Startseite
      </Button>
    }
  />
);

const Unauthorized = (
  <Alert
    style={{ margin: '40px' }}
    type="error"
    message="Keine Berechtigung"
    description="Sie haben keine Berechtigung für die Applikation"
    showIcon={true}
  />
);

const UnauthorizedProject = (
  <Alert
    style={{ margin: '40px' }}
    type="error"
    message="Keine Berechtigung"
    description="Sie haben keine Berechtigung für das Projekt"
    showIcon={true}
    action={
      <Button type="link" danger href="/" icon={<HomeOutlined />}>
        Zur Startseite
      </Button>
    }
  />
);

const changeNav = (path: string | null) => {
  window.location.href = `${window.location.origin}${path || '/'}`;
};

const linkExternal = (url: string | null) => {
  window.open(url || 'about:blank', '_blank');
};

const menuComponent = (openKeys: any[], selectedKeys: any[], firstLevel: TopMenuInfo[]) => {
  return (
    <Menu defaultOpenKeys={openKeys} defaultSelectedKeys={selectedKeys} mode="inline">
      {firstLevel.map(menu => {
        return menu.external ? (
          <li
            key={menu.path || menu.title}
            className={`ant-menu-item ${styles.externalLink}`}
            onClick={() => linkExternal(menu.url)}
          >
            {IconOrDefault(menu.icon)}
            <span>{menu.title}</span>
          </li>
        ) : (
          <Menu.Item
            key={menu.path || menu.title}
            icon={IconOrDefault(menu.icon)}
            onClick={() => (menu.external ? linkExternal(menu.url) : changeNav(menu.path))}
          >
            {menu.title}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const contentComponent = (
  currentPath: string,
  status: string,
  projekt: ProjektInfo | null,
  subMenus: SecondLevelMenu[] | null,
  url: string | null
) => {
  if (status === 'INVALID_SAP_NUMBER') {
    return InvalidSapNumber;
  }
  if (status === 'UNKNOWN_SAP_NUMBER') {
    return UnknownSapNumber;
  }
  if (status === 'UNAUTHORIZED') {
    return Unauthorized;
  }
  if (status === 'UNAUTHORIZED_PROJECT') {
    return UnauthorizedProject;
  }
  if (url) {
    if (projekt) {
      if (subMenus) {
        return (
          <>
            <ProjektHeader projekt={projekt} />
            <Tabs defaultActiveKey={currentPath} onChange={key => ((window as any).location = key)}>
              {subMenus.map(subMenu => (
                <TabPane tab={subMenu.title} key={subMenu.path} />
              ))}
            </Tabs>
            {getIframe(url)}
          </>
        );
      } else {
        return (
          <>
            <ProjektHeader projekt={projekt} />
            {getIframe(url)}
          </>
        );
      }
    } else {
      return getIframe(url);
    }
  }

  return UnknownPage;
};

const parseMenu = (menu: MenuData, currentPath: string, currentQueryParams: string) => {
  let openKeys = [];
  let selectedKeys = [];
  let defaultPath: string | null = null;

  const pathMapping = new Map();
  menu.firstLevel.forEach(menu => {
    if (menu.subMenus?.length > 0) {
      menu.subMenus.forEach(subMenu => {
        pathMapping.set(subMenu.path, {
          menu,
          url: subMenu.url,
          open: subMenu.path || subMenu.title,
          select: subMenu.path || subMenu.title,
        });
        defaultPath = defaultPath || subMenu.path;
      });
    } else {
      pathMapping.set(menu.path, {
        menu,
        url: menu.url,
        open: menu.path || menu.title,
        select: menu.path || menu.title,
      });
      defaultPath = defaultPath || menu.path;
    }
  });

  const currentMenu =
    pathMapping.get(currentPath) ||
    (menu.status === 'OK' && (currentPath === '/' || currentPath === '') ? pathMapping.get(defaultPath) : null);
  if (currentMenu) {
    openKeys.push(currentMenu.open);
    selectedKeys.push(currentMenu.select);
  }

  let currentUrl = currentMenu?.url;
  if (currentUrl && currentQueryParams) {
    currentUrl += currentQueryParams;
  }

  return {
    menuComponent: menuComponent(openKeys, selectedKeys, menu.firstLevel),
    contentComponent: contentComponent(currentPath, menu.status, menu.projekt, currentMenu?.menu?.subMenus, currentUrl),
  };
};

const getIframe = (url: string) => {
  return <iframe className={styles.iframe} title="Inhalt" name="content" src={url} />;
};

export const useUserMenu = () => {
  // currently each menu/route change will trigger whole page reload
  // no useState needed
  const currentPath = window.location.pathname;
  const currentQueryParams = window.location.search;
  const axios = useAxios();

  const { isLoading, isError, error, data } = useQuery('menu', async () => {
    const { data } = await axios.get(`menu?currentUrl=${encodeURIComponent(currentPath)}`, { withCredentials: true });
    return data;
  });

  if (isLoading) {
    return { menuComponent: <></>, contentComponent: <Spin /> };
  }
  if (isError) {
    console.error(error);
    return { menuComponent: <></>, contentComponent: InternalError };
  }

  const menu = parseMenu(data as MenuData, currentPath, currentQueryParams);
  return { menuComponent: menu.menuComponent, contentComponent: menu.contentComponent };
};
