import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import { EncodedResource, getSource, ResourcePreviewApi, ResourcePreviewState } from './EncodedResource';
import { Modal } from 'antd';

import styles from './PdfPreview.module.css';

const empty = { encodedResources: [], currentIndex: -1 } as ResourcePreviewState;

export const PdfPreview = forwardRef((props, ref: Ref<ResourcePreviewApi>) => {
  const [previewState, setPreviewState] = useState<ResourcePreviewState>(empty);

  useImperativeHandle<ResourcePreviewApi, ResourcePreviewApi>(ref, () => ({
    showResource(resource: EncodedResource) {
      if (resource) {
        setPreviewState({
          encodedResources: [
            {
              title: resource.title,
              url: getSource(resource),
            },
          ],
          currentIndex: 0,
        });
      }
    },
    showResources(resources: EncodedResource[], currentIndex: number) {
      if (resources?.length === 1) {
        setPreviewState({
          encodedResources: [
            {
              title: resources[0].title,
              url: getSource(resources[0]),
            },
          ],
          currentIndex: 0,
        });
      } else {
        console.warn('Preview for multiple PDFs not supported, ignored', resources);
      }
    },
  }));

  if (previewState.encodedResources.length === 0) {
    return <></>;
  }

  return (
    <Modal
      className={styles.pdfmodal}
      centered={true}
      visible={previewState.encodedResources.length > 0}
      title={previewState.encodedResources[0].title}
      onCancel={() => setPreviewState(empty)}
      footer={null}
      width={'90vw'}
    >
      <iframe
        className={styles.iframe}
        title="PDF Preview"
        name="pdf-preview"
        src={`/pdfviewer/web/viewer.html?file=${encodeURIComponent(previewState.encodedResources[0].url || '')}`}
      ></iframe>
    </Modal>
  );
});
