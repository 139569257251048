export interface ResourcePreviewApi {
  showResource(resource: EncodedResource): void;
  showResources(resources: EncodedResource[], currentIndex: number): void;
}

export interface EncodedResource {
  resource: string;
  title: string;
}

export interface ResourcePreview {
  title: string;
  url: string;
}

export interface ResourcePreviewState {
  encodedResources: ResourcePreview[];
  currentIndex: number;
}

export const getSource = (resource: EncodedResource): string => {
  return `${(window as any)._env_.RESOURCE_LINK}?resource=${resource.resource}&name=${encodeURIComponent(
    resource.title
  )}`;
};

export const downloadResource = (resource: EncodedResource) => {
  window.open(getSource(resource), '_blank');
};
