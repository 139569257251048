import React from 'react';
import { Spin } from 'antd';

interface Props {
  type: 'app' | 'content';
}

const LoadingIndicator = (props: Props) => {
  return (
    <div
      style={{
        height: props.type === 'app' ? '100vh' : '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default LoadingIndicator;
