import React from 'react';
import { ProjektKennzeichen } from '../domain/Menu';
import { Avatar, Tooltip } from 'antd';

interface ProjektKennzeichenProps extends React.HTMLAttributes<HTMLDivElement> {
  kennzeichen: ProjektKennzeichen[]
}

interface KZ {
  order: number
  short: string
  long: string
  color: string
}

const TAGS = new Map<string, KZ>();
TAGS.set(
  'UEBERGABEPAKET_COMPLETED',
  { order: 10, short: 'Ü', long: 'Übergabepaket vollständig', color: '#ac6118' });
TAGS.set(
  'INSTANDSETZUNGSPRUEFUNG_APPROVED',
  { order: 20, short: 'P', long: 'Instandsetzungsprüfung freigegeben', color: '#888888' });
TAGS.set(
  'INSTANDSETZUNGSFREIGABEBLATT_APPROVED',
  { order: 40, short: 'F', long: 'Instandsetzungsfreigabeblatt freigegeben', color: '#806c00' });
TAGS.set(
  'BABG',
  { order: 70, short: 'A', long: 'Bauseitig abgeschlossen', color: '#52AD35' });

const filterAndSort = (kennzeichen: ProjektKennzeichen[]) => {
  return kennzeichen
    .filter(kz => TAGS.has(kz.kennzeichen))
    .sort((a, b) => (TAGS.get(a.kennzeichen)?.order ?? 0) - (TAGS.get(b.kennzeichen)?.order ?? 0));
};

const dateFormat = (isoTimestamp: string) => new Date(isoTimestamp).toLocaleDateString('de-DE', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
});

export const ProjektKennzeichenAvatars: React.FC<ProjektKennzeichenProps> = props => {
  return <span className={props.className}>
    {filterAndSort(props.kennzeichen).map(kz => {
      const meta = TAGS.get(kz.kennzeichen);
      return <Tooltip key={`Tooltip-${kz.kennzeichen}`} title={(meta?.long ?? '?') + ' seit ' + dateFormat(kz.creationTime)} placement="top">
        <Avatar
          style={{ fontWeight: 'normal', margin: '0 0 5px 5px', backgroundColor: (meta?.color ?? '#FFFFFF') }}
          key={kz.kennzeichen}
          size={18}
          gap={5}
          alt={kz.kennzeichen}
        >
          {meta?.short ?? '?'}
        </Avatar>
      </Tooltip>;
    })}
  </span>;
};
